import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

const ContactPage = ({data}) => (
  <Layout pageInfo={{ pageName: "contact" }} active="contact">
    <SEO title="Contact" keywords={[`unhistoried`, `contact`, `brrrlabs`]} />
    <Container className="contact-page">
      <Row noGutters className="unhistoried-contact-row">
        <Col md={6} className="unhistoried-contact-col">
          <GatsbyImage className="unhistoried-contact-featured-image" imgStyle={{maxHeight:`80vh`,objectFit:`contain`}} image={data.contentfulGeneral.contactFeaturedImage.gatsbyImageData}  />
        </Col>
        <Col md={6} className="unhistoried-contact-col">
          {
            data.contentfulGeneral.briefOverview !== "-" &&
            <Row>
              <Col>
                <h4 className="unhistoried-contact-paragraph">{data.contentfulGeneral.briefOverview}</h4>
                <hr className="unhistoried-contact-hairline"></hr>
              </Col>
            </Row>
          }
          {
            (data.contentfulGeneral.contactEmail !== "-" || data.contentfulGeneral.contactPhoneNumber !== "-") &&
            <Row>
              <Col>
                <h4 className="unhistoried-contact-title">Email:</h4>
                <a target="_blank" href={data.contentfulGeneral.contactEmailLink}>
                  <h4 className="unhistoried-contact-value">{data.contentfulGeneral.contactEmail}</h4>
                </a>
                <h4 className="unhistoried-contact-title">Phone:</h4>
                <a target="_blank" href={data.contentfulGeneral.contactPhoneNumberLink}>
                  <h4 className="unhistoried-contact-value">{data.contentfulGeneral.contactPhoneNumber}</h4>
                </a>
                <hr className="unhistoried-contact-hairline"></hr>
              </Col>
            </Row>
          }
          {
            data.contentfulGeneral.address !== "-" &&
            <Row>
              <Col>
                <h4 className="unhistoried-contact-title">Address:</h4>
                <a target="_blank" href={data.contentfulGeneral.addressLink}>
                  <h4 className="unhistoried-contact-value">{data.contentfulGeneral.address}</h4>
                </a>
                <hr className="unhistoried-contact-hairline"></hr>
              </Col>
            </Row>
          }
          {
            (data.contentfulGeneral.contactSocialInstagram !== "-" ||
            data.contentfulGeneral.contactSocialTwitter !== "-" ||
            data.contentfulGeneral.contactSocialFacebook) &&
            <Row>
              <Col>
                {
                  data.contentfulGeneral.contactSocialInstagram !== "-" &&
                  <React.Fragment>
                    <h4 className="unhistoried-contact-title">Instagram:</h4>
                    <a target="_blank" href={data.contentfulGeneral.contactSocialInstagramLink}>
                      <h4 className="unhistoried-contact-value">{data.contentfulGeneral.contactSocialInstagram}</h4>
                    </a>
                  </React.Fragment>
                }
                {
                  data.contentfulGeneral.contactSocialTwitter !== "-" &&
                  <React.Fragment>
                    <h4 className="unhistoried-contact-title">Twitter:</h4>
                    <a target="_blank" href={data.contentfulGeneral.contactSocialTwitterLink}>
                      <h4 className="unhistoried-contact-value">{data.contentfulGeneral.contactSocialTwitter}</h4>
                    </a>
                  </React.Fragment>
                }
                {
                  data.contentfulGeneral.contactSocialFacebook !== "-" &&
                  <React.Fragment>
                    <h4 className="unhistoried-contact-title">Facebook:</h4>
                    <a target="_blank" href={data.contentfulGeneral.contactSocialFacebookLink}>
                      <h4 className="unhistoried-contact-value">{data.contentfulGeneral.contactSocialFacebook}</h4>
                    </a>
                  </React.Fragment>
                }
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const imgQuery = graphql`
query GeneralQuery {
  contentfulGeneral(contentful_id: {eq: "1fDxT0SD7yWPCDmLFmmaAx"}) {
    id
    address
    addressLink
    briefOverview
    contactEmail
    contactEmailLink
    contactPhoneNumber
    contactPhoneNumberLink
    contactSocialFacebook
    contactSocialFacebookLink
    contactSocialInstagram
    contactSocialInstagramLink
    contactSocialTwitter
    contactSocialTwitterLink
    contentful_id
    websiteName
    contactFeaturedImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
    }
  }
}`

export default ContactPage
